import React, { Fragment } from "react";

function PrivacyPolicy() {
  return (
    <Fragment>
      <div className=" space-y-8">
        <div id="section1" className="space-y-6">
          <div>
            <h1 className="maintitle">END USER LICENCE AGREEMENT</h1>
            <h3>Last updated December 13, 2022</h3>
          </div>
          <br />
          <p className="para ">
            Drama Station App is licensed to You (End-User) by Telenor Pakistan,
            located and registered at 345 Telenor Pakistan, Gulberg Greens Block
            B Gulberg Greens, Islamabad, Islamabad Capital Territory
            23200, Islamabad, Federal 44000, Pakistan ('<b>Licensor</b>'), for
            use only under the terms of this License Agreement.
          </p>
          <p className="para ">
            By downloading the Licensed Application from Google's software
            distribution platform ('Play Store'), and any update thereto (as
            permitted by this License Agreement), You indicate that You agree to
            be bound by all of the terms and conditions of
            this License Agreement, and that You accept
            this License Agreement. Play Store is referred to in
            this License Agreement as '<b>Services</b>'.
          </p>
          <p className="para ">
            The parties of this License Agreement acknowledge that the Services
            are not a Party to this License Agreement and are not bound by any
            provisions or obligations with regard to the Licensed Application,
            such as warranty, liability, maintenance and support
            thereof. Telenor Pakistan, not the Services, is solely responsible
            for the Licensed Application and the content thereof.
          </p>
          <p className="para ">
            This License Agreement may not provide for usage rules for the
            Licensed Application that are in conflict with the latest Google
            Play Terms of Service ('<b>Usage Rules</b>'). Telenor
            Pakistan acknowledges that it had the opportunity to review the
            Usage Rules and this License Agreement is not conflicting with them.
          </p>
          <p className="para ">
            Drama Station App when purchased or downloaded through the Services,
            is licensed to You for use only under the terms of
            this License Agreement. The Licensor reserves all rights not
            expressly granted to you. Drama Station App is to be used on devices
            that operate with Google's operating system ('Android').
          </p>
        </div>

        <div id="section2" className="space-y-6">
          <div>
            <h1 className="maintitle">TABLE OF CONTENTS</h1>
          </div>
          <div className="pl-10 para">
            <ol type="1">
              <li>THE APPLICATION</li>
              <li>SCOPE OF LICENCE</li>
              <li>TECHNICAL REQUIREMENTS</li>
              <li>MAINTENANCE AND SUPPORT</li>
              <li>USE OF DATA</li>
              <li>USER-GENERATED CONTRIBUTIONS</li>
              <li>CONTRIBUTION LICENCE</li>
              <li>LIABILITY</li>
              <li>WARRANTY</li>
              <li>PRODUCT CLAIMS</li>
              <li>LEGAL COMPLIANCE</li>
              <li>CONTACT INFORMATION</li>
              <li>TERMINATION</li>
              <li>THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</li>
              <li>INTELLECTUAL PROPERTY RIGHTS</li>
              <li>APPLICABLE LAW</li>
              <li>MISCELLANEOUS</li>
            </ol>
          </div>
        </div>
        <div id="section3" className="space-y-6">
          <div>
            <h1 className="title">1. THE APPLICATION</h1>
          </div>
          <p className="para ">
            Drama Station App ('Licensed Application') is a piece of software
            created to Drama Station App is a mobile application where you can
            watch your favorite dramas and movies. —
            And customized for Android mobile devices ('Devices'). It is used
            to to watch Pakistani Drama and old Pakistani movies and audio
            afsanay, khanian.
          </p>
          <p className="para ">
            Furthermore, it is used to More Video content and IVR content..
          </p>
        </div>
        <div id="section4" className="space-y-6">
          <div>
            <h1 className="title">2. SCOPE OF LICENCE</h1>
          </div>
          <p className="para ">
            2.1 This license will also govern any updates of the Licensed
            Application provided by Licensor that replace, repair, and/or
            supplement the first Licensed Application, unless a
            separate license is provided for such update, in which case the
            terms of that new license will govern.
          </p>
          <p className="para ">
            2.2 You may not copy (excluding when expressly authorized by
            this license and the Usage Rules) or alter the Licensed Application
            or portions thereof. You may create and store copies only on devices
            that you own or control for backup keeping under the terms of
            this license, the Usage Rules, and any other terms and conditions
            that apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that
            no unauthorized third parties may gain access to these copies at any
            time. If you sell your Devices to a third party, you must remove the
            Licensed Application from the Devices before doing so.
          </p>
          <p className="para ">
            2.3 Licensor reserves the right to modify the terms and conditions
            of licensing.
          </p>
        </div>
        <div id="section5" className="space-y-6">
          <div>
            <h1 className="title">3. TECHNICAL REQUIREMENTS</h1>
          </div>
          <p className="para ">
            3.1 Licensor attempts to keep the Licensed Application updated so
            that it complies with modified/new versions of the firmware and new
            hardware. You are not granted rights to claim such an update.
          </p>
          <p className="para ">
            3.2 You acknowledge that it is your responsibility to confirm and
            determine that the app end-user device on which you intend to use
            the Licensed Application satisfies the technical specifications
            mentioned above.
          </p>
          <p className="para ">
            3.3 Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </p>
        </div>
        <div id="section6" className="space-y-6">
          <div>
            <h1 className="title">4. MAINTENANCE AND SUPPORT</h1>
          </div>
          <p className="para ">
            4.1 The Licensor is solely responsible for providing any maintenance
            and support services for this Licensed Application. You can reach
            the Licensor at the email address listed in the Play Store Overview
            for this Licensed Application.
          </p>
          <p className="para ">
            4.2 Telenor Pakistan and the End-User acknowledge that the Services
            have no obligation whatsoever to furnish any maintenance and support
            services with respect to the Licensed Application.
          </p>
        </div>
        <div id="section7" className="space-y-6">
          <div>
            <h1 className="title">5. USE OF DATA</h1>
          </div>
          <p className="para ">
            You acknowledge that Licensor will be able to access and adjust your
            downloaded Licensed Application content and your personal
            information, and that Licensor's use of such material and
            information is subject to Your legal agreements with Licensor and
            Licensor's privacy policy, which can be accessed by On the
            Authentication/Authorization Screen.
          </p>
          <p className="para ">
            You acknowledge that the Licensor may periodically collect and use
            technical data and related information about your device, system,
            and application software, and peripherals, offer product support,
            facilitate the software updates, and for purposes of providing other
            services to you (if any) related to the Licensed Application.
            Licensor may also use this information to improve its products or to
            provide services or technologies to you, as long as it is in a form
            that does not personally identify you.
          </p>
        </div>
        <div id="section8" className="space-y-6">
          <div>
            <h1 className="title">6. USER-GENERATED CONTRIBUTIONS</h1>
          </div>
          <p className="para ">
            The Licensed Application may invite you to chat, contribute to, or
            participate in blogs, message boards, online forums, and other
            functionality, and may provide you with the opportunity to create,
            submit, post, display, transmit, perform, publish, distribute, or
            broadcast content and materials to us or in the Licensed
            Application, including but not limited to text, writings, video,
            audio, photographs, graphics, comments, suggestions, or personal
            information or other material (collectively, 'Contributions').
            Contributions may be viewable by other users of the Licensed
            Application and through third-party websites or applications. As
            such, any Contributions you transmit may be treated as
            non-confidential and non-proprietary. When you create or make
            available any Contributions, you thereby represent and warrant that:
          </p>
          <p className="para pl-10">
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party. <br /> 2. You are the
            creator and owner of or have the necessary licenses, rights,
            consents, releases, and permissions to use and to authorize us, the
            Licensed Application, and other users of the Licensed Application to
            use your Contributions in any manner contemplated by the Licensed
            Application and this License Agreement. <br />
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to
            use the name or likeness or each and every such identifiable
            individual person to enable inclusion and use of your Contributions
            in any manner contemplated by the Licensed Application and
            this License Agreement. <br />
            4. Your Contributions are not false, inaccurate, or misleading.{" "}
            <br />
            5. Your Contributions are not unsolicited
            or unauthorized advertising, promotional materials, pyramid schemes,
            chain letters, spam, mass mailings, or other forms of solicitation.
            <br />
            6. Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us). <br />
            7. Your Contributions do not ridicule, mock, disparage, intimidate,
            or abuse anyone. <br />
            8. Your Contributions are not used to harass or threaten (in the
            legal sense of those terms) any other person and to promote violence
            against a specific person or class of people. <br />
            9. Your Contributions do not violate any applicable law, regulation,
            or rule. <br />
            10. Your Contributions do not violate the privacy or publicity
            rights of any third party. <br />
            11. Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors. <br />
            12. Your Contributions do not include any offensive comments that
            are connected to race, national origin, gender, sexual preference,
            or physical handicap. <br />
            13. Your Contributions do not otherwise violate, or link to material
            that violates, any provision of this License Agreement, or any
            applicable law or regulation.
          </p>
          <p className="para ">
            Any use of the Licensed Application in violation of the foregoing
            violates this Licence Agreement and may result in, among other
            things, termination or suspension of your rights to use the Licensed
            Application.
          </p>
        </div>
        <div id="section9" className="space-y-6">
          <div>
            <h1 className="title">7. CONTRIBUTION LICENCE</h1>
          </div>
          <p className="para ">
            By posting your Contributions to any part of the Licensed
            Application or making Contributions accessible to the Licensed
            Application by linking your account from the Licensed Application to
            any of your social networking accounts, you automatically grant, and
            you represent and warrant that you have the right to grant, to us an
            unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
            transferable, royalty-free, fully-paid, worldwide right,
            and license to host, use copy, reproduce, disclose, sell, resell,
            publish, broad cast, retitle, archive, store, cache, publicly
            display, reformat, translate, transmit, excerpt (in whole or in
            part), and distribute such Contributions (including, without
            limitation, your image and voice) for any purpose, commercial
            advertising, or otherwise, and to prepare derivative works of, or
            incorporate in other works, such as Contributions, and grant
            and authorize sublicenses of the foregoing. The use and distribution
            may occur in any media formats and through any media channels.
          </p>
          <p className="para ">
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions.
          </p>
          <p className="para ">
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area in
            the Licensed Application. You are solely responsible for your
            Contributions to the Licensed Application and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions.
          </p>
          <p className="para ">
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2)
            to recategorise any Contributions to place them in more appropriate
            locations in the Licensed Application; and (3) to prescreen or
            delete any Contributions at any time and for any reason, without
            notice. We have no obligation to monitor your Contributions.
          </p>
        </div>
        <div id="section10" className="space-y-6">
          <div>
            <h1 className="title">8. LIABILITY</h1>
          </div>
          <p className="para ">
            8.1 Licensor’s responsibility in the case of violation of
            obligations and tort shall be limited to intent and gross
            negligence. Only in case of a breach of essential contractual duties
            (cardinal obligations), Licensor shall also be liable in case of
            slight negligence. In any case, liability shall be limited to the
            foreseeable, contractually typical damages. The limitation mentioned
            above does not apply to injuries to life, limb, or health.
          </p>
        </div>
        <div id="section11" className="space-y-6">
          <div>
            <h1 className="title">9. WARRANTY</h1>
          </div>
          <p className="para ">
            9.1 Licensor warrants that the Licensed Application is free of
            spyware, trojan horses, viruses, or any other malware at the time of
            Your download. Licensor warrants that the Licensed Application works
            as described in the user documentation.
          </p>
          <p className="para ">
            9.2 No warranty is provided for the Licensed Application that is not
            executable on the device, that has been unauthorized modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless if by Yourself or by third parties, or if
            there are any other reasons outside of Telenor Pakistan's sphere of
            influence that affect the executability of the Licensed Application.
          </p>
          <p className="para ">
            9.3 You are required to inspect the Licensed Application immediately
            after installing it and notify Telenor Pakistan about issues
            discovered without delay by email provided in Contact Information.
            The defect report will be taken into consideration and further
            investigated if it has been emailed within a period of ten (10) days
            after discovery.
          </p>
          <p className="para ">
            9.4 If we confirm that the Licensed Application is
            defective, Telenor Pakistan reserves a choice to remedy the
            situation either by means of solving the defect or substitute
            delivery.
          </p>
          <p className="para ">
            9.5 In the event of any failure of the Licensed Application to
            conform to any applicable warranty, you may notify the Services
            Store Operator, and Your Licensed Application purchase price will be
            refunded to you. To the maximum extent permitted by applicable law,
            the Services Store Operator will have no other warranty obligation
            whatsoever with respect to the Licensed Application, and any other
            losses, claims, damages, liabilities, expenses, and costs
            attributable to any negligence to adhere to any warranty.
          </p>
          <p className="para ">
            9.6 If the user is an entrepreneur, any claim based on faults
            expires after a statutory period of limitation amounting to twelve
            (12) months after the Licensed Application was made available to the
            user. The statutory periods of limitation given by law apply for
            users who are consumers.
          </p>
        </div>
        <div id="section12" className="space-y-6">
          <div>
            <h1 className="title">10. PRODUCT CLAIMS</h1>
          </div>
          <p className="para ">
            Telenor Pakistan and the End-User acknowledge that Telenor Pakistan,
            and not the Services, is responsible for addressing any claims of
            the End-User or any third party relating to the Licensed Application
            or the End-User’s possession and/or use of that Licensed
            Application, including, but not limited to:
          </p>
          <div className="para pl-10">
            <ol id="listview">
              <li>Product liability claims;</li>
              <li>
                Any claim that the Licensed Application fails to conform to any
                applicable legal or regulatory requirement; and
              </li>
              <li>
                claims arising under consumer protection, privacy, or similar
                legislation.
              </li>
            </ol>
          </div>
        </div>
        <div id="section13" className="space-y-6">
          <div>
            <h1 className="title">11. LEGAL COMPLIANCE</h1>
          </div>
          <p className="para ">
            You represent and warrant that you are not located in a country that
            is subject to a US Government embargo, or that has been designated
            by the US Government as a 'terrorist supporting' country; and that
            you are not listed on any US Government list of prohibited or
            restricted parties.
          </p>
        </div>
        <div id="section14" className="space-y-6">
          <div>
            <h1 className="title">12. CONTACT INFORMATION</h1>
          </div>
          <p className="para ">
            For general inquiries, complaints, questions or claims concerning
            the Licensed Application, please contact:
            <br />
            <br />
            Planet beyond Pvt 345 <br /> Telenor Pakistan, Gulberg Greens Block
            B Gulberg Greens, Islamabad, <br />
            Islamabad Capital Territory 23200 <br />
            Islamabad, Federal 44000 <br />
            Pakistan <br />
            complaint@planetbeyond.co.uk
          </p>
        </div>
        <div id="section15" className="space-y-6">
          <div>
            <h1 className="title">13. TERMINATION</h1>
          </div>
          <p className="para ">
            The license is valid until terminated by Telenor Pakistan or by you.
            Your rights under this license will terminate automatically and
            without notice from Telenor Pakistan if you fail to adhere to any
            term(s) of this license. Upon License termination, you shall stop
            all use of the Licensed Application, and destroy all copies, full or
            partial, of the Licensed Application.
          </p>
        </div>
        <div id="section16" className="space-y-6">
          <div>
            <h1 className="title">
              14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
            </h1>
          </div>
          <p className="para ">
            Telenor Pakistan represents and warrants that Telenor Pakistan will
            comply with applicable third-party terms of agreement when using
            Licensed Application.
          </p>
          <p className="para ">
            In Accordance with Section 9 of the 'Instructions for Minimum Terms
            of Developer's End-User License Agreement', Google's subsidiaries
            shall be third-party beneficiaries of this End
            User License Agreement and — upon Your acceptance of the terms and
            conditions of this License Agreement, Google will have the right
            (and will be deemed to have accepted the right) to enforce this End
            User License Agreement against You as a third-party beneficiary
            thereof.
          </p>
        </div>
        <div id="section17" className="space-y-6">
          <div>
            <h1 className="title">15. INTELLECTUAL PROPERTY RIGHTS</h1>
          </div>
          <p className="para ">
            Telenor Pakistan and the End-User acknowledge that, in the event of
            any third-party claim that the Licensed Application or the
            End-User's possession and use of that Licensed Application infringes
            on the third party's intellectual property rights, Telenor Pakistan,
            and not the Services, will be solely responsible for the
            investigation, defense, settlement, and discharge or any such
            intellectual property infringement claims.
          </p>
        </div>
        <div id="section18" className="space-y-6">
          <div>
            <h1 className="title">16. APPLICABLE LAW</h1>
          </div>
          <p className="para ">
            This License Agreement is governed by the laws of Pakistan excluding
            its conflicts of law rules.
          </p>
        </div>
        <div id="section19" className="space-y-6">
          <div>
            <h1 className="title">17. MISCELLANEOUS</h1>
          </div>
          <p className="para ">
            17.1 If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in
            a way that will achieve the primary purpose.
          </p>
          <p className="para ">
            17.2 Collateral agreements, changes and amendments are only valid if
            laid down in writing. The preceding clause can only be waived in
            writing.
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default PrivacyPolicy;
