import "./App.css";
import { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import TermsConditions from "./pages/TermsConditions";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms-conditions" element={<TermsConditions />}></Route>
      </Routes>
    </Fragment>
  );
}

export default App;
