import React, { Fragment } from "react";

function TermsConditions() {
  return (
    <Fragment>
      <div className=" space-y-8">
        <div id="section1" className="space-y-6">
          <div>
            <h1 className="maintitle text-[#9d3511]">Terms & Conditions</h1>
            <h3>Updated at 2023-01-03 </h3>
          </div>
        </div>
        <div id="section2" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">General Terms</h1>
          </div>
          <p className="para">
            By accessing and placing an order with Drama Station, you confirm
            that you are in agreement with and bound by the terms of service
            Contained in the Terms & Conditions outlined below, These terms
            apply to the entire website and any email or other type of
            communication between you and Drama Station. <br />
            Under no circumstances shall Drama Station team be liable for any
            direct, indirect, special, incidental or consequential damages.
            Including, but not limited to, loss of data or profit, arising out
            of the use, or the inability to use, the materials on this site.
            Even if Drama Station team or an authorized representative has been
            advised of the possibility of such damages. If your use of materials
            from this site results in the need for servicing, repair or
            correction of equipment or data, you assume any costs thereof <br />
            Drama Station will not be responsible for any outcome that may occur
            during the course of usage of our resources we reserve the rights to
            change prices and revise the resources usage policy in any moment.
          </p>
        </div>
        <div id="section3" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">License </h1>
          </div>
          <p className="para">
            Drama Station grants you a revocable, non-exclusive,
            nontransferable, limited license to download, install and use the
            app strictly in accordance with the terms of (his Agreement These
            Terms & Conditions are a contract between you and Drama Station
            (‘we,” “our,” or “us"} grants you a revocable, non-exclusive, non.
            transferable limited license to download, install and use the app
            strictly in accordance with the terms of this Agreement .
          </p>
        </div>
        <div id="section4" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Definitions and key terms{" "}
            </h1>
          </div>
          <p className="para">For this Terms & Conditions</p>
          <div>
            <ol className="pl-10 bullet">
              <li>
                <p className="para">
                  {" "}
                  <b>Cookie:</b> small amount of data generated by a website and
                  saved by your web browser. It is used to identify your
                  browser, provide analytics, remember information about you
                  such as your language preference or login information{" "}
                </p>
              </li>
              <li>
                <p className="para">
                  {" "}
                  <b>Company:</b> when this policy mentions “Company,” “we,”
                  “us,” or “our,” it refers to Telenor Pakistan, 345, Plot No.
                  55 , River View Avenue, Block 8, Gulberg Greens Islamabad that
                  is responsible for your information under this Privacy Policy
                </p>
              </li>
              <li>
                <p className="para">
                  {" "}
                  <b>Country:</b> where Drama Station or the owners/founders of
                  Drama Station are based, in this case is Pakistan
                </p>
              </li>
              <li>
                <p className="para">
                  {" "}
                  <b>Customer:</b> refers to the company, organization or person
                  that signs up to use the Drama Station Service to manage the
                  relationships with your consumers or service users. ¢ Device:
                  any interne connected device such as a phone, tablet, computer
                  or any other device that can be used to visit Drama
                </p>
              </li>{" "}
              <li>
                <p className="para">
                  {" "}
                  <b>Device:</b> any internet connected device such as a phone,
                  tablet, computer or any other device that can be used to visit
                  Drama Station and use the services.
                </p>
              </li>{" "}
              <li>
                <p className="para">
                  {" "}
                  <b>IP address:</b> Every device connected to the Internet is
                  assigned a number known as an Internet protocol (IP) address.
                  These numbers are usually assigned in geographic blocks. An IP
                  address can often be used to identify the location from which
                  a device is connecting to the Internet.
                </p>
              </li>
              <li>
                <p className="para">
                  {" "}
                  <b>Personnel:</b> refers to those individuals who are employed
                  by Drama Station or are under contract to perform a service on
                  behalf of one of the parties.
                </p>
              </li>
              <li>
                <p className="para">
                  {" "}
                  <b>Personal Data:</b> any information that directly,
                  indirectly, or in connection with other information including
                  a personal identification number allows for the identification
                  or identifiability of a natural person.
                </p>
              </li>
              <li>
                <p className="para">
                  {" "}
                  <b>Service:</b> refers to the service provided by Drama
                  Station as described in the relative terms (if available) and
                  on this platform. Third-party service: refers to advertisers,
                  contest sponsors, promotional and marketing partners, and
                  others ’who provide our content or whose products or services
                  we think may interest you.
                </p>
              </li>{" "}
              <li>
                <p className="para">
                  {" "}
                  <b>Website:</b> Drama Station's site, which can be accessed
                  via this URL:
                </p>
              </li>{" "}
              <li>
                <p className="para">
                  {" "}
                  <b>You:</b> a person or entity that is registered with Drama
                  Station to use the Services.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div id="section5" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Restrictions </h1>
          </div>
          <p className="para">
            You agree not to, and you will not permit others to:
          </p>
          <div>
            <ol className="pl-10 bullet">
              <li>
                <p className="para">
                  License, sell, rent, lease, assign, distribute, transmit,
                  host, outsource, disclose or otherwise commercially exploit
                  the service or make the platform available to any third party
                </p>
              </li>
              <li>
                <p className="para">
                  Modify, make derivative works of, disassemble, decrypt,
                  reverse compile or reverse engineer any part of the service
                  ice of copyright or trademark) of or its affiliates, partners,
                </p>
              </li>
              <li>
                <p className="para">
                  Remove, alter or obscure any proprietary notice (Including any
                  notice of copyright or trademark) of or its affiliates,
                  partners, suppliers or the licensors of the service.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div id="section6" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Your Suggestions </h1>
          </div>
          <p className="para">
            Any feedback, comments ideas, improvements or suggestions
            (collectively, “Suggestions”) provided by you to us with respect to
            the Service shall remain the sole and exclusive property of us. We
            shall be free to use, Copy, modify, publish, or redistribute the
            Suggestions for any purpose and in any way without any credit or any
            compensation to you.
          </p>
        </div>
        <div id="section7" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Your Consent </h1>
          </div>
          <p className="para">
            We've our Terms & Conditions to provide you with complete
            transparency into what is being set when you visit our site and how
            t's being used by using our service, registering an account, or
            making a purchase, you hereby consent to our Terms & Conditions.
          </p>
        </div>
        <div id="section8" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Changes To Our Terms & Conditions{" "}
            </h1>
          </div>
          <p className="para">
            You acknowledge and agree that we may stop (permanently or
            temporarily) providing the Service (or any features within the
            Service) to you or to users generally at our sole discretion,
            without prior notice to you. You may stop using the Service at any
            time. You do not need to specifically inform us when you stop using
            the Service. You acknowledge and agree that if we disable access to
            your account, you may be prevented from accessing the Service, your
            account details or any files or other materials which is contained
            in your account. !f we decide to change our Terms & Conditions, we
            will post those changes on this page, and/or update the Terms &
            Conditions modification date below.
          </p>
        </div>
        <div id="section9" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Modifications to Our service{" "}
            </h1>
          </div>
          <p className="para">
            We reserve the right to modify, suspend or discontinue, temporally
            or permanently, the service or any service to which it connects,
            with of without notice and without liability to you.
          </p>
        </div>
        <div id="section10" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Updates to Our service{" "}
            </h1>
          </div>
          <p className="para">
            We may from time to time provide enhancements or improvements to the
            features/ functionality of the service, which may include patches,
            bug fixes, updates, upgrades and other modifications ("Updates")
            Updates may modify or delete certain features and/or functionalities
            of the service. You agree that we have no obligation to (i) provide
            any Updates, or (ii) continue to provide or enable any particular
            features and/or functionalities of the service to you. You further
            agree that all Updates will be (i) deemed to constitute an integral
            part of the service, and (ti) subject to the terms and conditions of
            this Agreement.
          </p>
        </div>
        <div id="section11" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Third-Party Services </h1>
          </div>
          <p className="para">
            We may display, include or make available third-party content
            (including data, information, applications and other products
            services) of provide links to third-party websites or services
            ("Third-party Services”). You acknowledge and agree that we shall
            not be responsible for any Third-Party Services, including their
            accuracy, completeness, timeliness, validity, copyright compliance,
            legality, decency, quality or any other aspect thereof. We do not
            assume and shall not have any liability or responsibility to you or
            any other person or entity for any Third-Party Services. Third-Party
            Services and links thereto are provided solely as a convenience to
            you and you access and use them entirely at your own risk and
            subject to such third parties’ terms and conditions.
          </p>
        </div>
        <div id="section12" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Term and Termination </h1>
          </div>
          <p className="para">
            This Agreement shall remain in effect until terminated by you or us.
            We may, in its sole discretion, at any time and for any or no
            reason, suspend or terminate this Agreement with or without prior
            notice. This Agreement will terminate immediately, without prior
            notice from us, in the event that you fail to comply with any
            provision of this Agreement. You may also terminate this Agreement
            by deleting the service and all copies thereof from your computer.
            Upon termination of this Agreement, you shall cease all use of the
            service and delete all copies of the service from your computer.
            Termination of this Agreement will not limit any of our rights or
            remedies at law or in equity in case of breach by you (during the
            term of this Agreement) of any of your obligations under the present
            Agreement.
          </p>
        </div>{" "}
        <div id="section13" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Copyright Infringement Notice{" "}
            </h1>
          </div>
          <p className="para">
            If you are a copyright owner or such owner’s agent and believe any
            material from us constitutes an infringement on your copyright,
            please contact us setting forth the following information: (a) a
            physical or electronic signature of the copyright owner or a person
            authorized to act on his behalf; (b) identification of the material
            that is claimed to be infringing; (c) your contact information,
            including your address, telephone number, and an email; (d) a
            statement by you that you have a good faith belief that use of the
            material is not authorized by the copyright owners, and (e) the a
            statement that the information in the notification is accurate, and,
            under penalty of perjury you are authorized to act on behalf of the
            owner
          </p>
        </div>{" "}
        <div id="section14" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Amendments to this Agreement{" "}
            </h1>
          </div>
          <p className="para">
            We reserve the right, at its sole discretion, to modify or replace
            this Agreement at any time. If a revision is material we will
            provide at least 30 days’ notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion. By continuing to access or use our service after
            any revisions become effective, you agree to be bound by the revised
            terms. if you do not agree to the new terms, you are no longer
            authorized to use our service
          </p>
        </div>
        <div id="section15" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Entire Agreement </h1>
          </div>
          <p className="para">
            The Agreement constitutes the entire agreement between you and us
            regarding your use of the service and supersedes all prior and
            contemporaneous written or oral agreements between you and us. You
            may be subject to additional terms and conditions that apply when
            you use or purchase other services from us, which we will provide to
            you at the time of such use or purchase.
          </p>
        </div>
        <div id="section16" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Updates to Our Terms </h1>
          </div>
          <p className="para">
            We may change our Service and policies, and we may need to make
            changes to these Terms so that they accurately reflect cur Service
            and policies. Unless otherwise required by law: we will notify you
            (for example! through our Service) before we make changes to these
            Terms and give you an Opportunity, lo review them before they go in
            to effect. Then, if you continue to use the Service, you will be
            bound by the updated Terms, lf you do not want to agree to these or
            any any updated Terms, you can delete your account.
          </p>
        </div>
        <div id="section17" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Intellectual Property </h1>
          </div>
          <p className="para">
            Our platform and its entire contents, features and functionality
            (including but not limited to, al information, software, text,
            displays, images, video and audio, and the design, selection and
            arrangement thereof), are owned by us: Its licensors or other
            providers of such material and are protected by Pakistan and
            international copyright, trademark, patent trade secret and other
            intellectual property or proprietary rights laws. The maternal may
            not be copied, modified, reproduced, downloaded or distributed in
            any way, in whole or in part, without the express prior written
            permission of us, unless, and except as is expressly provided in
            these Terms & Conditions. Any unauthorized use of the material is
            prohibited.
          </p>
        </div>
        <div id="section18" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">
              Agreement to Arbitrate{" "}
            </h1>
          </div>
          <p className="para">
            This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A
            DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
            REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Drama Stations
            INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute,
            action, or other controversy between you and us concerning the
            Services or this agreement, whether in contract, warranty, tort,
            statute, regulation, ordinance, or any other legal or equitable
            basis. “Dispute” will be given the broadest possible meaning
            allowable under law.
          </p>
        </div>
        <div id="section19" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Notice of Dispute </h1>
          </div>
          <p className="para">
            In the event of a dispute, you or us must give the other a Notice of
            Dispute, which is a written statement that sets forth the name,
            address, and contact information of the party giving it, the facts
            giving rise to the dispute, and the relief requested. You must send
            any Notice of Dispute via email to. We will send any Notice of
            Dispute to you by mail to your address if we have it, or otherwise
            to your email address. You and us will attempt to resolve any
            dispute through informal negotiation within sixty (60) days from the
            date the Notice of Dispute to sent. After sixty (60) days, you or us
            may commence arbitration.
          </p>
        </div>
        <div id="section20" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Submission and Privacy</h1>
          </div>
          <p className="para">
            In the event that you submit or post any ideas, creative
            suggestions, designs, Photographs, information, advertisements, data
            or proposals, including ideas for new or improved products,
            services, features, technologies or promotions, you expressly agree
            that such submissions will automatically be treated as no
            confidential and non-proprietary and will become the sole property
            of us without any compensation or credit to you whatsoever. We and
            our affiliates shall have no obligations with respect to such
            submissions or posts and may use the ideas contained in such
            submissions or posts for any purposes in any medium in perpetuity,:
            including, but not limited to, developing; manufacturing, [and
            marketing products, and services using such ideas.
          </p>
        </div>
        <div id="section21" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Miscellaneous</h1>
          </div>
          <p className="para">
            If for any reason a court of competent jurisdiction finds any
            provision or portion of these Terms & Conditions to be
            unenforceable, the remainder of these Terms & Conditions will
            continue in full force and effect. Any waiver of any provision of
            these Terms & Conditions will be effective only if in writing and
            signed by an authorized representative of us. We will be entitled to
            injunctive or other equitable Relief (without the obligations of
            posting any bond or surety) in the event of any breach or
            anticipatory breach by you. We operate and control our Service from
            our offices in Pakistan. The Service is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation. Accordingly, those persons who choose to access our
            Service from other locations do so on their own initiative and are
            solely responsible for compliance with local laws, if and to the
            extent local laws are applicable. These Terms & Conditions (which
            include and incorporate our Privacy Policy) contains the entire
            understanding, and supersedes all prior understandings, between you
            and us concerning its subject matter, and cannot be changed or
            modified by you. The section headings used in this Agreement are for
            convenience only and will not be given any legal import.
          </p>
        </div>
        <div id="section22" className="space-y-2">
          <div>
            <h1 className="maintitle text-[#9d3511]">Disclaimer</h1>
          </div>
          <p className="para">
            We are not responsible for any content, code or any other
            imprecision. We are not be liable for any special, direct, indirect,
            consequential, or incidental damages or any damages whatsoever,
            whether in an action of contract, negligence or other tort, arising
            out, of or in connection; with the use, of the Service or the
            contents of the Service. We reserve the right to make additions,
            deletion or modification to the content on the Service at any time
            without prior notice. <br /> Our Service and its contents are
            provided "as is" and "as available” without any warranty or
            representations of any kind, whether express or implied. We are a
            distributor and not a publisher of the content supplied by third
            parties; as such, our exercises no editorial control over such
            content and makes no warranty or representation as to the accuracy,
            reliability or currency of any. Information, content, service or
            merchandise provided through or accessible via our Service. Without
            limiting the foregoing, We specifically disclaim all warranties and
            representations i in any content transmitted on or in connection
            with our Service or on sites that may appear as links on our
            Service, or in the products provided as a part of, or otherwise in
            connection with, our Service, including without limitation any
            warranties of merchantability, fi fitness for a particular purpose
            or no infringement of third party rights. No oral advice or written
            information given by us or any of its affiliates, employees,
            officers, directors, agents, or the like will create a warranty.
            Price and availability information i is subject to change without
            notice. Without limiting the foregoing, we do not warrant that our
            Service will be uninterrupted, uncorrupted, timely, or error-free.
            <br /> <br />
            Contact Us <br />
            Don’t hesitate to contact us if you have any questions.
            <ul className="pl-10 bullet">
              <li>PBDigital@planetbeyond.co.uk</li>
            </ul>
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default TermsConditions;
